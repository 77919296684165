.apexcharts-legend-series .apexcharts-legend-marker {
  left: -4px !important;
  top: 2px !important;
}

.css-vgy1cz-MuiResponsiveChart-container>svg {
  width: auto;
}

.css-vgy1cz-MuiResponsiveChart-container {
  overflow-x: scroll;
}