body {
  background-color: #f2f2f2;
}

.container {
  margin-top: 5rem;
  padding-top: 2.5rem;
  padding-left: 3rem;
  padding-right: 3rem;
  background-color: #f2f2f2;
  /* height: 100%; */
  width: 100%;
}

.excludingcontainerstyling{
  margin-top: 0;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  border-top-left-radius: 0;
}