body {
  background-color: #f2f2f2;
  background-image: url(assets/images/background_image/bg_truck.png);
  background-repeat: repeat;
  background-size: cover;
  overflow-y: auto;

}

.container {
  margin-top: 5rem;
  padding-top: 2.5rem;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 3rem;
  background: transparent;
}

.excludingcontainerstyling{
  margin-top: 0;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  border-top-left-radius: 0;
}

.MuiBreadcrumbs-li > *,  .MuiBreadcrumbs-li, .MuiBreadcrumbs-separator {
  color: #FFFFFF;
}